import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {MainPage} from "./page/MainPage";
import {ProfilePage} from "./page/ProfilePage";
import * as cookie from "./cookie";
import {Login} from "./component/Login";
import {LoginService} from "./service/LoginService";
import {stopEvents} from "./service/event";
import {HttpException} from "./exception";
import {Register, RegisterState} from "./component/Register";
import {PingService} from "./service/PingService";
import {RegisterService} from "./service/RegisterService";

export interface AppState {
    state: 'initial' | 'logged-in' | 'logged-out' | 'do-login' | 'register';
    login: string | null;
    message: string | undefined;
    error: string | undefined;
    loginOptions: LoginOptions | undefined;
}


const INITIAL_STATE: AppState = {
    state: 'initial',
    login: cookie.get('connect.sid'),
    message: undefined,
    error: undefined,
    loginOptions: undefined
};

export interface LoginOptions {
    username: string,
    password: string,
    keepLoggedIn: boolean
}

export function App() {

    const [state, setState] = useState(INITIAL_STATE);
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', event => {
                console.log("Received event of type", event.data.type);
                if (event.data.type === 'unauthorized') {
                    console.log('Unauthorized request');
                    setState(state => {
                        return {...state, state: 'logged-out', login: null};
                    });
                }
            });
        } else {
            console.error("ServiceWorker not found! Some things may not work properly");
        }
    }, []);

    function handleLogout() {
        stopEvents();
        LoginService.logout().then(function () {

            setState(INITIAL_STATE);
            console.log("Logged out");
        });


    }

    async function checkState(state: AppState) {
        if (state.state !== 'do-login') {
            return;
        }
        const options = state.loginOptions;
        if (!options) {
            return;
        }
        try {
            await LoginService.login(options.username, options.password, {keepLoggedIn: options.keepLoggedIn});
            console.log("Login successful");
            setState({...state, state: 'logged-in'})

        } catch (e) {
            console.error("Unable to login:", e);
            setState({...state, state: 'logged-out', error: (e as HttpException).error});
        }

    }

    function handleRegisterClicked() {
        setState(state => ({...state, state: 'register', error: ''}))
    }

    useEffect(() => {
        checkState(state)
    }, [state]);


    function handleLogin(credentials: LoginOptions) {
        setState(state => ({...state, state: "do-login", loginOptions: credentials}));
    }

    async function handleRegister(credentials: RegisterState) {
        const result = await RegisterService.register(credentials);
        if (result.success) {
            console.log("Registration was a success");
            setState(state => {
                const newState: AppState = {...state, state: "do-login", error: '', message: "You will receive a confirmation email with a link to to enable the account"};
                console.log("NS", newState);
                return newState;
            });
        } else {
            console.log("Registration was a failure");
            setState(state => ({...state,  error: result.message}));
        }
    }

    async function checkPing() {
        const loggedIn = await PingService.ping();
        if (loggedIn) {
            setState(state => ({...state, state: "logged-in"}));
        } else {
            setState(state => ({...state, state: "logged-out"}));
        }
    }

    console.log("State:", state);
    switch (state.state) {
        case "initial":
            checkPing();
            return <div></div>

        case "logged-in":
            return <Router>
                <Route exact path="/" component={MainPage}/>
                <Route exact path="/profile" render={() => <ProfilePage onLogout={handleLogout}/>}/>
                <Route exact path="/note" component={MainPage}/>
                <Route exact path="/note/:id" component={MainPage}/>
            </Router>;
        case "register":
            return <Register onSubmit={handleRegister} error={state.error}> </Register>;
        default:
            return <Login error={state.error} onSubmit={(credentials: any) => handleLogin(credentials)}
                          message={state.message}
                          onRegisterClicked={handleRegisterClicked}/>;
    }

}
