import {Modal} from "./shared/Modal";
import React, {ChangeEvent, FormEvent, useState} from "react";
import {LoginOptions} from "../App";

export interface RegisterProps {
    onSubmit: (state: RegisterState) => void;
    error?: string;
    message?: string;
}

export interface RegisterState {
    username: string;
    email: string;
    password: string;
}

const INITIAL_STATE: RegisterState = {
    username: '',
    email: '',
    password: '',
};

export function Register(props: RegisterProps) {
    const [state, setState] = useState(INITIAL_STATE);

    function handleUsernameChange(event: ChangeEvent<HTMLInputElement>) {
        setState(state => ({...state, username: event.target.value}));
    }

    function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
        setState(state => ({...state, email: event.target.value}));
    }

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
        setState(state => ({...state, password: event.target.value}));
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        props.onSubmit(state);
    }

    function renderError() {
        if (props.error) {
            return <div className="form-row error">Unable to register: {props.error}</div>;
        } else if (props.message) {
            return <div className="form-row notify">{props.message}</div>;
        } else {
            return <div className="form-row">&nbsp;</div>;
        }

    }


    return <div>
        <form onSubmit={e => handleSubmit(e)}>
            <Modal background="opaque">
                <div>Please enter username and password</div>
                <div>
                    <div className="form-row">
                        <span className="label">Username:</span>
                    </div>
                    <div className="form-row">
                        <input id="login-username" type="text" value={state.username}
                               onChange={e => handleUsernameChange(e)}/>
                    </div>
                    <div className="form-row">
                        <span className="label">Email:</span>
                    </div>
                    <div className="form-row">
                        <input id="login-email" type="text" value={state.email}
                               onChange={e => handleEmailChange(e)}/>
                    </div>
                    <div className="form-row">
                        <span className="label">Password:</span>
                    </div>
                    <div className="form-row">
                        <input id="login-password" type="password" value={state.password}
                               onChange={e => handlePasswordChange(e)}/>
                    </div>
                    <div className="form-row checkbox">
                        &nbsp;
                    </div>
                    {renderError()}
                </div>
                <div>
                    <div>We are in limited test, only email from approved domains will be able to register</div>
                    <div className="button-row">
                        <button>Register</button>
                    </div>
                </div>
            </Modal>
            <input type="submit" style={{display: 'none'}}/>
        </form>
    </div>;

}
