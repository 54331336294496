import {RegisterState} from "../component/Register";
import {RegisterOutcomeDbo} from "../dbo/RegisterOutcomeDbo";

class RegisterService {
    async register(credentials: RegisterState): Promise<RegisterOutcomeDbo> {
        const headers = {
            'Content-Type': 'application/json'
        };
        try {
            const response = await fetch(`api/register`, {
                method: "POST",
                headers,
                body: JSON.stringify(credentials)
            });
            const result = await response.json();
            return result as RegisterOutcomeDbo;
        } catch( e ) {
            console.error("Register request failed:", e);
            return {
                success: false,
                message: "Server error, please try later"
            }
        }
    }
}


const instance = new RegisterService();
export {instance as RegisterService};
