import {LabelService} from "../service/Label";
import {removeLabelFromNotes} from "./Notes";
import {State} from "./action";
import {NewLabelDbo} from "../dbo/LabelDbo";


async function createLabel(state: State, label: any): Promise<Partial<State>> {
    const result = await LabelService.save(label);
    const labels = state.labels.slice();
    labels.push(result);
    return {labels};
}


async function editLabel(state: State, updatedLabel: NewLabelDbo): Promise<Partial<State>>{
    const result = await LabelService.save(updatedLabel);
    const labels = [];
    for (let label of state.labels) {
        if (label.id === result.id) {
            labels.push(result);
        } else {
            labels.push(label);
        }
    }
    return {labels};
}

export function showLabelsModal(state: State): Partial<State> {
    const modal = {...state.modal, labels: {show: true}};
    return { modal };

}
