import {ItemDbo} from "../dbo/ItemDbo";
import {NoteDbo} from "../dbo/NoteDbo";


function removeByNoteId(oldItems: ItemDbo[], noteId: string): ItemDbo[] {
    console.info(`Removing note ${noteId}`);
    const items = oldItems.filter(item => item.note.id !== noteId);
    if (items.length < oldItems.length) {
        console.debug(`Note with id ${noteId} deleted`);
        console.debug(`Now we have ${items.length} items`);
    }
    return items;
}

function removeByItemKey(oldItems: ItemDbo[], itemKey: string): ItemDbo[] {
    console.info(`Removing note with key ${itemKey}`);
    const items = oldItems.filter(item => item.key !== itemKey);
    if (items.length < oldItems.length) {
        console.debug(`Item with id ${itemKey} deleted`);
        console.debug(`Now we have ${items.length} items`);
    } else {
        console.debug(`Item with id ${itemKey} not found and so not deleted`);
    }
    return items;
}


function create(oldItems: ItemDbo[], note: NoteDbo): ItemDbo[] {
    const alreadyCreated = oldItems.filter(item => item.note.id === note.id).length > 0;
    if (!alreadyCreated) {
        console.debug(`Item with id ${note.id} was not created by me, adding it`);
        const item : ItemDbo =  {key: note.id || '', note, selected: false,unsaved: true };
        const items = [...oldItems,item];
        console.debug(`Now we have ${items.length} items`);
        return items;
    } else {
        console.debug(`Item ${note.id} already created`);
        return oldItems;
    }
};

function update(items: ItemDbo[], note: NoteDbo, preserveSelected: boolean): ItemDbo[] {
    console.info(`Updating note with id ${note.id}`);
    return items.map(item => {
        if (item.note.id !== note.id) {
            return item;
        }
        if (!preserveSelected) {
            return {
                key: note.id,
                note,
                unsaved: false,
                selected: false
            };
        } else {
            return {
                key: note.id,
                selected: item.selected,
                note,
                unsaved: false
            };
        }
    });
};

function  getItemByKey( items: ItemDbo[], key: string): ItemDbo  | undefined {
    for (const item of items) {
        if (item.key === key) {
            return item;
        }
    }
    return undefined;
}

function sameItem( x: ItemDbo, y: ItemDbo) {
    return x.key === y.key &&
        x.note.id === y.note.id &&
        x.note.content === y.note.content &&
        x.note.title === y.note.title &&
        // FIXME
        x.note.labels === y.note.labels &&
        x.note.color === y.note.color;
}


export const ItemService = {
    removeByNoteId, removeByItemKey, update, create, getItemByKey, sameItem
};
