import {NoteService} from "../service/NoteService";
import {ItemService} from "../service/ItemService";
import {ItemDbo} from "../dbo/ItemDbo";
import {NoteDbo} from "../dbo/NoteDbo";
import {LabelDbo} from "../dbo/LabelDbo";
import {MenuState, ModalState} from "../state";
import {hasLabel} from "./Notes";
import {SortDirectionEnum, SortOrderEnum} from "../dbo/SortOrderEnum";
import {v4 as uuidv4} from 'uuid';

export interface State {
    state: 'initial' | 'loading' | 'loaded'
    message: { type: string, text: string } | null;
    // global list of items
    items: ItemDbo[];
    // above items once they are filtered
    filteredItems: ItemDbo[];
    // above items once they are sorted
    sortedItems: ItemDbo[];
    filter: string;
    currentLabel: LabelDbo | null;
    labels: LabelDbo[];
    menu: MenuState;
    modal: ModalState;
    error?: any;
    sortOrder: SortOrderEnum;
    sortDirection: SortDirectionEnum;
}

export type Pstate = Partial<State>;

export async function loadDataAsync(state: State): Promise<ItemDbo[]> {
    const notes = await NoteService.getAll();
    const loadedItems = notes.map(function (note: NoteDbo) {
        return {
            note,
            selected: false,
            unsaved: false,
            key: note.id || ''
        };
    });
    return loadedItems;
}

export function setItems(items: ItemDbo[], filter: string, sortOrder: string, sortDirection: string, currentLabel: LabelDbo | null): Partial<State> {
    let filteredItems = items;
    if (currentLabel) {
        filteredItems = filteredItems.filter((item: any) => hasLabel(item, currentLabel));
    }
    if (filter) {
        filteredItems = filterItems(items, filter);
    }
    const sortedItems = filteredItems.slice();
    sortedItems.sort(sorters[`${sortOrder}-${sortDirection}`]);

    return {items, filter, filteredItems, sortedItems, currentLabel};
}

export function setItems2(items: ItemDbo[], state: State): State {
    const updates = setItems(items, state.filter, state.sortOrder, state.sortDirection, state.currentLabel);
    return {...state, ...updates};
}

function recUpdate(item: any, path: any, value: any): any {
    if (path.length === 0) {
        return Object.assign({}, item, value);
    }
    if (item === undefined) {
        item = {};
    }
    const key = path[0];
    const child = recUpdate(item[key], path.slice(1), value);
    return {...item, [key]: child};
}


function update(item: any, path: string, value: any) {
    return recUpdate(item, path.split('.'), value);
}

function plainText(html: string) {
    var div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
}

export function filterItems(items: any[], filter: string) {
    const lowered = filter.toLocaleLowerCase();
    return items.filter(item => {
        /**
         * Always display currently selected item
         */
        if (item.selected) {
            return true;
        }
        if (item.note.title.toLocaleLowerCase().indexOf(lowered) >= 0) {
            return true;
        }
        const plain = plainText(item.note.content).toLocaleLowerCase();
        if (plain.indexOf(lowered) >= 0) {
            return true;
        }
        return false;
    });
}


export function updateNotes(state: State, event: any): State {
    let {filter, items, currentLabel, sortOrder, sortDirection} = state;
    switch (event.status) {
        case 'deleted':
            items = ItemService.removeByNoteId(items, event.note.id);
            break;
        case 'created':
            const newItems = ItemService.create(items, event.note);
            if (newItems === items) {
                return state;
            }
            items = newItems;
            break;
        case 'updated':
            items = ItemService.update(items, event.note, true);
            break;
        default:
            console.error("Unhandled note event", event.status);
            return state;
    }
    return setItems2(items, state);
}


export function selectItem(state: State, noteId: any): Partial<State> {
    let newItems: any[] = [];
    state.items.forEach(item => {
        const note = item.note;
        if (note.id === noteId) {
            newItems.push({...item, selected: true});
        } else if (item.selected) {
            newItems.push({...item, selected: false});
        } else {
            newItems.push(item);
        }
    });
    return setItems(newItems, state.filter, state.sortOrder, state.sortDirection, state.currentLabel);
}


export function deselectItem(state: State, noteId: any): Partial<State> {
    let newItems: any[] = [];
    state.items.forEach(item => {
        const note = item.note;
        if (note.id === noteId) {
            newItems.push({...item, selected: false});
        } else {
            newItems.push(item);
        }
    });
    return {items: newItems};
}


export function deselectItems(state: State): Partial<State> {
    let newItems: any[] = [];
    state.items.forEach(item => {
        if (item.selected) {
            newItems.push({...item, selected: false});
        } else {
            newItems.push(item);
        }
    });
    return {items: newItems};
}


export function newItem(state: State, options: { title: string | null, content: string | null }): State {
    const items = state.items.slice();
    const id = uuidv4();
    const item: ItemDbo = {
        key: id,
        note: {
            id,
            title: options.title || '',
            content: options.content || '',
            meta: {
                contentType: 'text/plain'
            },
            color: '',
            labels: [],
            creationDate: new Date(),
            modificationDate: new Date()
        },
        unsaved: true,
        selected: true
    };
    items.push(item);
    return setItems2(items, state);
}


const sorters: { [key: string]: (a: ItemDbo, b: ItemDbo) => number } = {
    'modificationDate-asc': (a, b) => new Date(a.note.modificationDate).getTime() - new Date(b.note.modificationDate).getTime(),
    'modificationDate-desc': (a, b) => new Date(b.note.modificationDate).getTime() - new Date(a.note.modificationDate).getTime(),
    'creationDate-asc': (a, b) => new Date(a.note.creationDate).getTime() - new Date(b.note.creationDate).getTime(),
    'creationDate-desc': (a, b) => new Date(b.note.creationDate).getTime() - new Date(a.note.creationDate).getTime(),
    // 'dueDate-asc': (a,b) => a.note.dueDate.getTime() - b.note.dueDate.getTime(),
    // 'dueDate-desc': (a,b) => b.note.dueDate.getTime() - a.note.dueDate.getTime(),
}

export function deleteItem(state: State, item: ItemDbo): State {
    const items = state.items.filter(i => i.note.id !== item.note.id);
    return setItems2(items, state);
}


export function hasData(note: any): boolean {
    return note.title || note.content;
}

export function replaceItem(items: ItemDbo[], item: ItemDbo): ItemDbo[] {
    const result = [];
    console.log("replace item", items, item);
    for (const i of items) {
        if (i.note.id === item.note.id) {
            result.push(item)
        } else {
            result.push(i)
        }
    }
    return result;
}


export async function saveItem(item: ItemDbo, note: NoteDbo): Promise<ItemDbo> {
    const savedNote = await NoteService.save(note);
    item = {...item, note: savedNote};
    return item;
}


export function searchText(state: State, text: string): Pstate {
    const {items, currentLabel} = state;
    return setItems(items, text, state.sortOrder, state.sortDirection, currentLabel);
}

export function toggleSelectedLabel(state: State, newLabel: any): Pstate {
    const {items, currentLabel, filter} = state;
    console.log(currentLabel, newLabel);
    if (currentLabel && newLabel.id === currentLabel.id) {
        newLabel = null;
    }
    return setItems(items, filter, state.sortOrder, state.sortDirection, newLabel);
}

export function closeModal(state: State, modalName: any): Pstate {
    const modal = {...state.modal, [modalName]: {show: false}};
    return {modal};
}



