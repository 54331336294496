import {HttpException} from '../exception.js';
import {NoteDbo} from "../dbo/NoteDbo";


function htmlEscape(str: string) {
    return str
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
}

// function validURL(str) {
//   var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
//     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
//     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
//     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
//     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
//     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
//   return !!pattern.test(str);
// }

class NoteService {

    fromPlainText(title: string | null, content: string, url: string) {
        const htmlUrl = '<a href="' + url + '">' + url + '</a>';
        const htmlContent = content.split('\n').map(line => "<p>" + htmlEscape(line) + "</p>").join("\n") + htmlUrl;
        return {
            title,
            content: htmlContent
        };
    }

    async getAll(): Promise<NoteDbo[]> {
        const result = await fetch('api/note');
        if (result.ok) {
            const notes = (await result.json()).notes as NoteDbo[];
            for (const note of notes) {
                note.creationDate = new Date(note.creationDate);
                note.modificationDate = new Date(note.modificationDate);
            }
            return notes;
        } else {
            throw new HttpException(result);
        }
    }

    async save(note: NoteDbo) {
        let result;
        const headers = {
            'Content-Type': 'application/json'
        };

        if (note.id) {
            result = await fetch(`api/note/${note.id}`, {
                method: "PUT",
                headers,
                body: JSON.stringify(note)
            });
        } else {
            result = await fetch(`api/note`, {
                method: "POST",
                headers,
                body: JSON.stringify(note)
            });
        }
        if (result.ok) {
            const note = await result.json() as NoteDbo;
            console.log("Saved note:", note);
            note.creationDate = new Date(note.creationDate);
            note.modificationDate = new Date(note.modificationDate);
            return note;
        } else {
            throw new HttpException(result);
        }
    };

    async patch(note: Partial<NoteDbo>) {
        const result = await fetch(`api/note/${note.id}`, {
            headers: {
                "content-type":"application/json"
            },
            body: JSON.stringify(note),
            method: "PATCH"
        });
        if (result.ok) {
            return null;
        } else {
            console.log(result);
            throw new HttpException(result);
        }
    }

    async delete(note: NoteDbo) {
        const result = await fetch(`api/note/${note.id}`, {
            method: "DELETE"
        });
        if (result.ok) {
            return null;
        } else {
            console.log(result);
            throw new HttpException(result);
        }
    }

    isSameNote(note1: NoteDbo, note2: NoteDbo): boolean {
        return note1.id === note2.id && note1.title == note2.title && note1.labels.join("----") === note2.labels.join("----") &&
            note1.content === note2.content;
    }
};

const instance = new NoteService();

export {instance as NoteService};
