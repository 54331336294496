let source : EventSource | null  = null;

export function initEvents() {
    console.log("Initializing events");
    if (!source) {
        source = new EventSource(`/api/events`);
    }
}


export function stopEvents() {
    if (source) {
        source.close();
        source = null;
    }
}

export function addEventListener(eventType: string, callback : Function) {
    function cb(event: any) {
        const data = JSON.parse(event.data);
        const type = event.type;
        console.info("Received event of type", type, "with data", data);
        callback({...data, type});
    }
    return source?.addEventListener(eventType, cb);
}

