import React, {ChangeEvent, FormEvent, useState} from "react";
import {Modal} from "./shared/Modal";
import {LoginOptions} from "../App";

interface LoginProps {
    error: string | undefined,
    message: string | undefined;
    onSubmit: (credentials: LoginOptions) => void;
    onRegisterClicked: () => void;
}

const INITIAL_STATE: LoginOptions = {
    username: '',
    password: '',
    keepLoggedIn: false
};

export function Login(props: LoginProps) {

    const [state, setState] = useState(INITIAL_STATE);

    function handleUsernameChange(event: ChangeEvent<HTMLInputElement>) {
        setState(state => ({...state, username: event.target.value}));
    }

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
        setState(state => ({...state, password: event.target.value}));
    }

    function handleKeepLoggedInChange(event: ChangeEvent<HTMLInputElement>) {
        setState(state => ({...state, keepLoggedIn: !state.keepLoggedIn}));
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        props.onSubmit(state);
    }

    function renderError() {
        console.log("PROPS", props);
        if (props.error) {
            console.log("Error", props.error);
            return <div className="form-row error"> Unable to login: {props.error}</div>;
        } else if (props.message) {
            console.log("Message", props.message);
            return <div className="form-row notify">{props.message}</div>;
        } else {
            return <div className="form-row">&nbsp;</div>;
        }

    }

    return <div>
        <form onSubmit={e => handleSubmit(e)}>
            <Modal background="opaque">
                <div>Please enter username and password</div>
                <div>
                    <div className="form-row">
                        <span className="label">Name:</span>
                    </div>
                    <div className="form-row">
                        <input id="login-username" type="text" value={state.username}
                               onChange={e => handleUsernameChange(e)}/>
                    </div>
                    <div className="form-row">
                        <span className="label">Password:</span>
                    </div>
                    <div className="form-row">
                        <input id="login-password" type="password" value={state.password}
                               onChange={e => handlePasswordChange(e)}/>
                    </div>
                    <div className="form-row checkbox">
                        &nbsp;
                    </div>
                    <div className="form-row checkbox">
                        <input id="keep-logged" type="checkbox" onChange={e => handleKeepLoggedInChange(e)}
                               checked={state.keepLoggedIn}/>Keep me logged in
                    </div>
                    {renderError()}
                </div>
                <div>
                    <div>Not registered ? <a href="#" onClick={props.onRegisterClicked}>Register now</a></div>
                    <div className="button-row">
                        <button>Login</button>
                    </div>
                </div>
            </Modal>
            <input type="submit" style={{display: 'none'}}/>
        </form>
    </div>;
}
