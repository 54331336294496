class PingService {
    async ping(): Promise<boolean> {
        console.log("Pinging server");
        const result = await fetch('/api/ping');
        if (result.status < 400) {
            console.log("Ping successful");
            return true;
        } else {
            console.log("Ping failed");
            return false;
        }
    }
}

const instance = new PingService();

export { instance as PingService} ;
